<template>
  <nav
    ref="gsapRef"
    class="fixed left-6 top-1/2 flex h-[232px] w-[14px] -translate-y-1/2 transform-gpu"
    :class="isMounted ? 'visible' : 'invisible'"
  >
    <svg
      width="14"
      height="232"
      viewBox="0 0 14 232"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        ref="lineRef"
        d="M12 231C8.13401 231 5 179.513 5 116C5 52.4873 8.13401 1 12 1"
        stroke="url(#paint0_linear_638_8815)"
      />
      <!--
      <circle cx="7" cy="34" r="7" transform="rotate(90 7 34)" fill="#12E2FF" />
      <circle cx="5" cy="116" r="2" transform="rotate(90 5 116)" fill="#12E2FF" />
      <circle cx="6" cy="75" r="2" transform="rotate(90 6 75)" fill="#12E2FF" />
      <circle cx="6" cy="157" r="2" transform="rotate(90 6 157)" fill="#12E2FF" />
      <circle cx="7" cy="198" r="2" transform="rotate(90 7 198)" fill="#12E2FF" />
      <circle cx="7" cy="34" r="2" transform="rotate(90 7 34)" fill="#12E2FF" />
      -->
      <defs>
        <linearGradient
          id="paint0_linear_638_8815"
          x1="8.99995"
          y1="1"
          x2="8.99994"
          y2="231"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#AFF4FD" stop-opacity="0" />
          <stop offset="0.148913" stop-color="#12E2FF" />
          <stop offset="0.856522" stop-color="#12E2FF" />
          <stop offset="1" stop-color="#12E2FF" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>

    <NuxtLink
      v-for="(item, idx) in links"
      :key="item.title"
      ref="navItemRefs"
      :aria-label="item.title"
      :to="item.href"
      class="circle absolute block h-1 w-1 transform-gpu cursor-pointer rounded-full bg-[cyan]"
      :class="{
        'scale-[3.5]': isAnimationComplete && activeIdx === idx,
        'left-[5px] top-[39px]': idx === 0,
        'left-[4px] top-[70px]': idx === 1,
        'left-[3px] top-[101px]': idx === 2,
        'left-[3px] top-[132px]': idx === 3,
        'left-[4px] top-[163px]': idx === 4,
        'left-[5px] top-[194px]': idx === 5,
        'transition-transform duration-300 ease-out hover:scale-[2.6]': isAnimationComplete
      }"
      @click="handleClick($event)"
    ></NuxtLink>
  </nav>
</template>

<script setup lang="ts">
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'

export interface ScrollSectionNavProps {
  links: Array<{
    title: string
    href: string
  }>
}

const props = defineProps<ScrollSectionNavProps>()

const isMounted = ref(false)
const isAnimationComplete = ref(false)
const navItemRefs = ref([])
const activeIdx = ref(0)
const lineRef = ref(null)

const emit = defineEmits(['ready'])

const handleClick = (el: MouseEvent) => {
  if (!el.target) {
    return
  }
  gsap.to(window, { duration: 1.5, scrollTo: el.target.hash, overwrite: 'auto' })
}

const setActive = (idx: number) => {
  activeIdx.value = idx
}

const gsapRef = shallowRef<HTMLElement | null>(null)
let st: ScrollTrigger | null = null
let ctx: any = null

onMounted(() => {
  isMounted.value = true
  gsap.registerPlugin(ScrollTrigger)
  gsap.registerPlugin(ScrollToPlugin)
  gsap.registerPlugin(DrawSVGPlugin)

  if (navItemRefs.value.length > 0) {
    props.links.forEach((a, idx) => {
      const section = a.href

      st = ScrollTrigger.create({
        trigger: section,
        start: 'top center',
        end: 'bottom center',
        onToggle: (self) => self.isActive && setActive(idx)
      })
    })

    emit('ready')
  }

  if (gsapRef.value) {
    ctx = gsap.context((self: any) => {
      gsap.set(lineRef.value, { drawSVG: '100% 100%' })
      gsap.set(self.selector('.circle'), { scale: 0 })
      gsap.to(lineRef.value, { duration: 1.2, delay: 1, drawSVG: '0 100%' })
      gsap.to(self.selector('.circle'), {
        delay: 1,
        scale: 1,
        stagger: {
          each: 0.17,
          from: 'start'
        },
        ease: 'power1.out',
        onComplete: () => {
          gsap.set(self.selector('.circle'), { clearProps: 'all' })
          isAnimationComplete.value = true
        }
      })
    }, gsapRef.value)
  }
})

onUnmounted(() => {
  if (st) {
    st.kill()
  }
  if (ctx) {
    ctx.revert()
  }
})
</script>
